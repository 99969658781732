
import { Route, Routes } from "react-router-dom";
import "./App.css"
import Prediction from "./Pages/Prediction/Prediction";
import Home from "./Pages/Home/Home";

const App = ()=>{
    return <div className="bg-container d-flex justify-content-center align-items-center">
            <Routes>
                <Route path="/" element={<Home />}></Route>
                <Route path="/prediction" element={<Prediction />}></Route>
            </Routes>
            {/* <Select /> */}
        </div>
}

export default App;