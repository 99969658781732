import { Autocomplete, TextField } from "@mui/material"
import "./AutoComplete.css"

const AutoComplete = ({label,options,value,change})=>{
    const eventHandler = (e)=>{
        change(e.target.innerText);
    }
    return <Autocomplete className="col-md-10 col-sm-12 col-12"
    disablePortal
    id={label}
    options={options}
    value={value}
    inputValue={value}
    onInputChange={(event, newInputValue) => {
        change(newInputValue);
    }}
    onChange={eventHandler}
    renderInput={(params) => <TextField {...params} label={label} focused/>}
  />
}

export default AutoComplete