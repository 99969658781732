import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import FormControlLabel from '@mui/material/FormControlLabel';
import FormControl from '@mui/material/FormControl';
import FormLabel from '@mui/material/FormLabel';
import "./RadioButton.css"

const RadioButton = ({label,options,value,change})=>{
    const eventHandler = (e)=>{
        change(e.target.value);
    }
    return <FormControl className='col-md-5 col-sm-12'>
    <FormLabel id={label}>{label}</FormLabel>
    <RadioGroup
      row
      aria-labelledby={label}
      name={label}
      value={value}
      onChange={eventHandler}
    >
      
      {
        options.map((option)=><FormControlLabel value={option} control={<Radio color={'secondary'} />} label={option} />)
      }
    </RadioGroup>
  </FormControl>
}

export default RadioButton;