import MenuItem from '@mui/material/MenuItem';
import "./Select.css"
import { TextField } from '@mui/material';
const SelectMenu = ({label,menu,value,change})=>{
    const selectHandler = (e)=>{
        change(e.target.value)
    }
    return <div className='col-md-4 col-6 col-12'>
        <TextField variant='filled' label={label} size='large' select fullWidth={true} value={value} onChange={selectHandler} sx={{color:"#ff0088"}} focused>
            {
                value==="Choose Option" && 
                <MenuItem value={"Choose Option"} selected>Choose Option</MenuItem>
            }
            {
                menu.map((value,key)=><MenuItem value={value} key={key} >{value}</MenuItem>)
            }
        </TextField>
    </div>
}

export default SelectMenu