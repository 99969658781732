import LoadingButton from '@mui/lab/LoadingButton';
import { useState } from "react";
import axios from "axios";


const SubmitButton = ({battingTeam,bowlingTeam,venue,over,runsScored,last3Overs,wickets,innings,setScore})=>{
    const [loading,setLoading] = useState(false);
    const eventHandler = async ()=>{
        setLoading(true);
        try
        {
            const data = await axios.post("https://direct-kora-lace-841b1c30.koyeb.app/predict",{
                battingTeam,
                bowlingTeam,
                venue,
                "currentOver": over,
                runsScored,
                last3Overs,
                wickets,
                innings
              })
            setScore(data.data.Score)
            setLoading(false);
        }
        catch(e)
        {
            setLoading(false);
            console.log(e)
        }
    }
    return <LoadingButton  variant="contained" loading={loading} sx={{background:"#ff0088"}} onClick={eventHandler}>
                Submit
           </LoadingButton>
}

export default SubmitButton