import "./Prediction.css"
import SelectMenu from "../../Components/Input/Select/Select";
import { Avatar, IconButton, Stack, Typography } from "@mui/material";
import { useState } from "react";
import vsLogo from "./vs.png"
import AutoComplete from "../../Components/Input/AutoComplete/AutoComplete";
import RadioButton from "../../Components/Input/Radio/RadioButton";
import Text from "../../Components/Input/Text/Text";
import SubmitButton from "../../Components/Button/SubmitButton/SubmitButton";

const Prediction = ()=>{
    const teams = ["India", "Pakistan", "Ireland", "Canada", "United States of America","England", "Australia", "Namibia", "Scotland", "Oman","New Zealand", "West Indies", 'Afghanistan', "Uganda", "Papua New Guinea","South Africa", "Sri Lanka", "Bangladesh", "Netherlands", "Nepal"];
    const venues = ['SuperSport Park',
 'Dubai International Cricket Stadium',
 'Happy Valley Ground',
 'Meersen',
 'Al Amerat Cricket Ground Oman Cricket (Ministry Turf 1)',
 "Lord's",
 'Gahanga International Cricket Stadium. Rwanda',
 'Coolidge Cricket Ground',
 'Bellerive Oval',
 'King George V Sports Ground',
 'Pallekele International Cricket Stadium',
 'Kerava National Cricket Ground',
 'R Premadasa Stadium',
 'The Village',
 'Sharjah Cricket Stadium',
 'Amini Park',
 'Shere Bangla National Stadium',
 'Civil Service Cricket Club',
 'Marsa Sports Club',
 'Gaddafi Stadium',
 'Grange Cricket Club Ground',
 'La Manga Club Bottom Ground',
 'Integrated Polytechnic Regional Centre','Sydney Cricket Ground',
 'UKM-YSD Cricket Oval',
 'Hagley Oval',
 'The Rose Bowl',
 'ICC Academy',
 'Saurashtra Cricket Association Stadium',
 'Sportpark Het Schootsveld',
 'Independence Park',
 'Rajiv Gandhi International Cricket Stadium',
 'Wankhede Stadium',
 'Pierre Werner Cricket Ground',
 'Al Amerat Cricket Ground Oman Cricket (Ministry Turf 2)',
 'Sir Vivian Richards Stadium',
 'Beausejour Stadium',
 'Arun Jaitley Stadium',
 'Kensington Oval',
 'Manuka Oval',
 'Hurlingham Club Ground',
 'Goldenacre',
 'Moara Vlasiei Cricket Ground',
 'OUTsurance Oval',
 'Sophia Gardens',
 'Zayed Cricket Stadium','Adelaide Oval',
 'Vidarbha Cricket Association Stadium',
 'Bayer Uerdingen Cricket Ground',
 'Trent Bridge',
 'Sheikh Abu Naser Stadium',
 'Newlands',
 'JSCA International Stadium Complex',
 'Old Trafford',
 'Greenfield International Stadium',
 'Holkar Cricket Stadium',
 'Bayuemas Oval',
 'MA Chidambaram Stadium',
 'Providence Stadium',
 'Mission Road Ground',
 'Sheikh Zayed Stadium',
 'Harare Sports Club',
 'Gymkhana Club Ground',
 'National Stadium',
 'Indian Association Ground',
 'Arnos Vale Ground',
 'Bulawayo Athletic Club',
 'New Wanderers Stadium',
 'Eden Park',
 'Svanholm Park',
 'County Ground', 'Tribhuvan University International Cricket Ground',
        'Kinrara Academy Oval',
 'St Albans Club',
 'M.Chinnaswamy Stadium',
 'Riverside Ground',
 'Europa Sports Complex',
 'Terdthai Cricket Ground',
 'Western Australia Cricket Association Ground',
 'Desert Springs Cricket Ground',
 'Sano International Cricket Ground',
 'Willowmoore Park',
 'Tikkurila Cricket Ground',
 'VRA Ground',
 'Kingsmead',
 'Eden Gardens',
 'McLean Park',
 'National Cricket Stadium',
 'Kennington Oval',
 'Seddon Park',
 'Royal Brussels Cricket Club Ground',
 'Rawalpindi Cricket Stadium',
 'Khan Shaheb Osman Ali Stadium',
 'Gahanga International Cricket Stadium',
 'National Sports Academy',
        'Entebbe Cricket Oval',
 'GMHBA Stadium',
 'Lisicji Jarak Cricket Ground',
 "St George's Park",
 'ICC Academy Ground No 2',
 'Mahinda Rajapaksa International Cricket Stadium',
 'White Hill Field',
 'Scott Page Field',
 'Warner Park',
 'Punjab Cricket Association Stadium',
 'Sylhet Stadium',
 'Melbourne Cricket Ground',
 'Tolerance Oval',
 'The Wanderers Stadium',
 'Maharashtra Cricket Association Stadium',
 'Rajiv Gandhi International Stadium',
 'Queens Sports Club',
 "Queen's Park Oval",
 'Punjab Cricket Association IS Bindra Stadium',
 'GB Oval',
 'Westpac Stadium',
 'West End Park International Cricket Stadium',
 'Central Broward Regional Park Stadium Turf Ground',
        'M Chinnaswamy Stadium',
 'Belgrano Athletic Club Ground',
 'Hazelaarweg',
 'Castle Avenue',
 'Wanderers Cricket Ground',
 'Stadium Australia',
 'Windsor Park',
 'Bready Cricket Club',
 'Sportpark Westvliet',
 'Perth Stadium',
 'Senwes Park',
 'Zahur Ahmed Chowdhury Stadium',
 'ICC Global Cricket Academy',
 'Mombasa Sports Club Ground',
 'Green Park',
 'Subrata Roy Sahara Stadium',
 'Bay Oval',
 'Boland Park',
 'Edgbaston',
 'Himachal Pradesh Cricket Association Stadium',
 'Narendra Modi Stadium',
 'Bharat Ratna Shri Atal Bihari Vajpayee Ekana Cricket Stadium',
        'United Cricket Club Ground',
 'University of Lagos Cricket Oval',
 'R.Premadasa Stadium',
 'Brisbane Cricket Ground',
 'Buffalo Park',
 'Carrara Oval',
 'Kyambogo Cricket Oval',
 'Sawai Mansingh Stadium',
 'Greater Noida Sports Complex Ground',
 'Malahide',
 'Sabina Park',
 'Barabati Stadium',
 'College Field',
 'Headingley',
 'P Sara Oval',
 'Gucherre Cricket Ground',
 'Tony Ireland Stadium',
 'Sylhet International Cricket Stadium',
 'Darren Sammy National Cricket Stadium',
 'Grange Cricket Club',
 'Lugogo Cricket Oval',
 'Simonds Stadium',
 'Feroz Shah Kotla','Solvangs Park',
 'Dr. Y.S. Rajasekhara Reddy ACA-VDCA Cricket Stadium',
 'AMI Stadium',
 'Moses Mabhida Stadium',
 'Santarem Cricket Ground',
 'Mangaung Oval',
 'Sardar Patel Stadium',
 'John Davies Oval',
 'Sportpark Maarschalkerweerd',
 'Wanderers',
 'University Oval',
 'Jade Stadium',
 'Brian Lara Stadium',
 'Clontarf Cricket Club Ground',
 'Brabourne Stadium',
 'Sky Stadium',
 'Maple Leaf North-West Ground',
 'Barsapara Cricket Stadium',
 'Saxton Oval',
 'De Beers Diamond Oval',
 'Nassau County International Cricket Stadium']
    const [battingTeam,setBattingTeam] = useState("India");
    const [bowlingTeam,setBowlingTeam] = useState("Pakistan");
    const [venue,setVenue] = useState("Kensington Oval");
    const [innings,setInnings] = useState(1);
    const [currentOver,setCurrentOver] = useState(4);
    const [runsScored,setRunsScored] = useState(0);
    const [wickets,setWickets] = useState(0);
    const [runsScoredInLast3Overs,setRunsScoredInLast3Overs] = useState(0);
    const [score,setScore] = useState();
    return <div className="glass my-5 col-md-8 col-sm-12 col-12">
            <Typography variant="h4" mt={5} textAlign={"center"} sx={{color:"#ff0088"}}>T20 Score Predictor</Typography>
            <Stack alignItems={"center"} justifyContent={"center"} mx={"auto"} my={2} width={"80%"} direction="column">
                <Stack justifyContent={"center"} mx={"auto"} my={2} width={"80%"} gap={2} direction="row" useFlexGap flexWrap="wrap">
                    <SelectMenu label={"Batting Team"} menu={teams} value={battingTeam} change={setBattingTeam} />
                    <IconButton disabled size="large">
                        <Avatar src={vsLogo} size="large" />
                    </IconButton>
                    <SelectMenu label={"Bowling Team"} menu={teams} value={bowlingTeam} change={setBowlingTeam} />
                </Stack>
                <Stack justifyContent={"center"} mx={"auto"} my={2} width={"80%"} gap={4} direction="row" useFlexGap flexWrap="wrap">
                    <AutoComplete label={"Venue"} options={venues} value={venue} change={setVenue} />
                </Stack>
                <Stack justifyContent={"center"} mx={"auto"} my={2} width={"80%"} gap={4} direction="row" useFlexGap flexWrap="wrap">
                    <SelectMenu label={"Current Over"} menu={[4,5,6,7,8,9,10,11,12,13,14,15,16,17,18,19,20]} value={currentOver} change={setCurrentOver} />
                    <SelectMenu label={"Wickets"} menu={[0,1,2,3,4,5,6,7,8,9]} value={wickets} change={setWickets} />
                </Stack>
                <Stack justifyContent={"center"} mx={"auto"} my={2} width={"80%"} gap={4} direction="row" useFlexGap flexWrap="wrap">
                    <Text label={"Runs Scored Till Now"} value={runsScored} change={setRunsScored} />
                    <Text label={"Runs Scored in Last 3 Overs"} value={runsScoredInLast3Overs} change={setRunsScoredInLast3Overs} />
                </Stack>
                <Stack justifyContent={"center"} mx={"auto"} my={2} width={"70%"} gap={4} direction="column" useFlexGap flexWrap="wrap">
                    <RadioButton label={"Innings"} options={[1,2]} value={innings} change={setInnings} />
                    {
                        score &&
                        <>
                            <Typography variant="h5">Score :</Typography>
                            <Typography className="text-light text-center" variant="h1">{score}</Typography>
                        </>
                    }
                    <SubmitButton battingTeam={battingTeam} bowlingTeam={bowlingTeam} innings={innings} last3Overs={runsScoredInLast3Overs} over={currentOver} runsScored={runsScored} venue={venue} wickets={wickets} setScore={setScore} />
                </Stack>
            </Stack>
        </div>
}

export default Prediction